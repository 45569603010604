.MenuItem {
  margin: 15px 10px;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.1);
  background-color: white;

  .is-open .inactive {
    display: none;
  }

  .is-closed .active {
    display: none;
  }

  .item-trigger {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    min-height: 40px;
    margin: 0 15px;
    padding: 15px 10px 15px 5px;

    p, img {
      margin-top: auto;
      margin-bottom: auto;
      vertical-align: middle;
    }

    p {
      line-height: 25px;
      color: #484848;
      font-weight: bold;
      font-size: 10pt;
      margin-left: 5px;
      margin-right: 10px;
      flex-grow: 1;
    }

    img {
      height: 30px;
    }
  }

  &:last-of-type {
    margin-bottom: 30px
  }

  .inner {
    padding: 0 20px 20px 20px;

    .info {
      margin: 0;
      font-size: 10pt;
      font-weight: bold;

      &.green {
        color: #72B86F
      }

      &.orange {
        color: #FF9900
      }

      &.red {
        color: #D1574D
      }

      &.blue {
        color: #4CB0F8
      }
    }

    .suitable-for {
      border: solid 0.5px #E6E8EA;
      border-radius: 6px;
      margin: 20px -10px;
      padding: 20px;

      .body {
        display: flex;
        flex-wrap: wrap;
      }

      .row {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        p {
          margin: 0;
          font-size: 11pt;
          line-height: 25px;
        }
      }
    }

    .ingredients {
      border: solid 0.5px #E6E8EA;
      border-radius: 6px;
      margin: 20px -10px 0px -10px;
      padding: 10px 10px;
      display: flex;
      flex-direction: column;
      gap: 1em;

      h4 {
        color: #606161;
        font-weight: bold;
        margin: 0;
      }
    }

    .allergens {
      border: solid 0.5px #E6E8EA;
      border-radius: 6px;
      margin: 20px -10px 0px -10px;
      padding: 20px;

      .row {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0
        }

        p {
          margin: 0;
          line-height: 25px;
        }

        .upper {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          p {
            font-size: 11pt;
            font-weight: bold;
            color: #8F8F8F;
          }
        }

        .lower {
          p {
            font-size: 11pt;
          }
        }
      }
    }
  }
}