html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #F6F7F9;
}

@media screen and (min-width: 992px) {
  .App {
    width: 50%;
    // height: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.scene {
  // position: absolute;
  // top: 0px;
  // left: 0px;
  // right: 0px;
  // bottom: 0px;
  // margin: 0px auto;
  height: 100%;
}

h1, h2, h3, p {
  color: #545454;
}

button {
  height: 55px;
  border: none;
  border-radius: 6px;
  font-size: 12pt;
  outline: none;
  cursor: pointer;

  &.primary {
    color: white;
    background-image: linear-gradient(to bottom right, #61D8FB, #4AACF8);
  }

  &.secondary {
    background: none;
    color: #707070;
  }
}

.text-center {
  text-align: center;
}