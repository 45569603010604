.RequirementsScene {
  background-color: #F6F7F9;
  padding: 25px 15px;

  .header {
    padding: 10px;

    h2 {
      font-size: 14pt;
      margin: 0px;
      color: #4A4A4A;
    }

    p {
      line-height: 20px;
      font-size: 10pt;
      margin: 20px 0px;
      margin-right: 50px;
      color: #6E6E6F;
      font-weight: 600;
    }
  }

  .card {
    background-color: white;
    border-radius: 6px;
    margin-bottom: 15px;
    padding: 20px;
    min-height: 100px;

    -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.08);

    h3 {
      margin: 5px 0px;
      font-size: 10pt;
    }

    // // Allows room for button with absolute position
    // &:last-of-type {
    //     border-bottom: 70px solid transparent;
    // }

    .options-list {
      display: flex;
      flex-wrap: wrap;

      .Option {
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 520px) {
      .options-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .continue {
    width: 100%;
  }
}
