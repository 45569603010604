.MenuCategory {

  .is-open .inactive {
    display: none;
  }

  .is-closed .active {
    display: none;
  }

  .is-open .category-trigger {
    border-bottom: none;
  }

  .category-trigger {
    display: flex;
    height: 40px;
    margin: 0px 25px;
    padding: 15px 10px;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.06);
    justify-content: space-between;

    p, img {
      margin-top: auto;
      margin-bottom: auto;
    }

    p {
      line-height: 40px;
      margin: 0px;
    }

    img {
      height: 30px;
    }
  }

  // &:first-of-type { margin-top: 20px }
  &:last-of-type {
    margin-bottom: 30px
  }
}