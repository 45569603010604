.traffic-icon {
  svg.fa-circle {
    color: #D8DADD;
  }

  svg {
    color: white;
  }

  &.red {
    svg.fa-circle {
      color: #F43D3D
    }
  }

  &.orange {
    svg.fa-circle {
      color: #FF9900
    }
  }

  &.blue {
    svg.fa-circle {
      color: #04ADFF
    }
  }

  &.green {
    svg.fa-circle {
      color: #13BF5B
    }
  }
}