.MenuSection {
  background-color: #F8F9FB;

  .section-trigger {
    height: 30px;
    background-image: linear-gradient(to bottom right, #5CCFFA, #4AACF8);
    display: flex;
    padding: 15px 35px;
    justify-content: space-between;

    p {
      line-height: 30px;
      color: white;
      font-weight: bold;
      margin: 0px;
    }

    img {
      height: 30px;
      transform: rotate(180deg);
    }
  }

  .is-open .section-trigger img {
    transform: rotate(0deg);
  }

  .none-available {
    text-align: center;
    color: darkgrey;
    font-size: 10pt;
    line-height: 50px;
    font-weight: bold;
  }
}