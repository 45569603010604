.DisclaimerScene {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 20px;
  flex-grow: 1;
  background-color: white;

  .logo-container {
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 10px;
    max-height: 300px;

    img {
      max-height: 300px;
      object-fit: contain;
    }

    p {
      font-size: 14pt;
      font-weight: 500;
      color: #C2C2C3;
      text-align: center;
      background-color: #F7F7F8;
      line-height: 60px;
    }
  }

  .details {
    flex-grow: 1;
    min-height: 200px;
    margin-bottom: 50px;

    h1 {
      text-align: center;
      font-size: 16pt;
      color: #4A4A4A;
    }

    p {
      font-size: 11pt;
      width: 300px;
      margin: 0px auto;
      color: #707070;
      text-align: center;
      line-height: 22px;
      white-space: pre-wrap;
    }
  }


  .actions {
    flex-shrink: 0;
    height: fit-content;
    width: 100%;

    button {
      width: 100%;

      &.secondary {
        margin-top: 20px;
      }
    }
  }
}