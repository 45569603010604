.Menu {
  display: flex;
  flex-direction: column;
  background-color: #F8F9FB;
  padding-top: 100px;

  .inner {
    // overflow: scroll;
  }

  .warning {
    margin: 20px;
    padding: 20px;
    color: #F43D3D;
    background-color: #FDEBEB;
    font-size: 10pt;
    font-weight: bold;
    white-space: pre-wrap;
  }

  .toggle {
    background-color: white;
    border-radius: 4px;
    margin: 20px;
    min-height: 30px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      margin: 0px;
      font-size: 10pt;
      margin-top: auto;
      margin-bottom: auto;
    }

    .outer {
      border-radius: 11px;
      height: 22px;
      border: solid 1px rgba(0, 0, 0, 0.15);
      width: 40px;
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.01);

      .inner {
        height: 16px;
        width: 16px;
        border: solid 1px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin: 2px;
        background-color: white;
      }

      &.active {
        background-color: #8DD07E;

        .inner {
          border-color: white;
          margin-left: auto;
        }
      }
    }
  }

  .selections {
    background-color: white;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    padding: 20px;

    .upper {
      display: flex;
      justify-content: space-between;

      h2 {
        font-size: 11pt;
        margin: 0px;
      }

      p {
        color: #04B1FF;
        text-decoration: underline;
        cursor: pointer;
        font-size: 10pt;
        font-weight: 600;
        margin: auto 0px;
      }
    }

    .lower {
      display: flex;
      flex-wrap: wrap;

      span.none-selected {
        font-size: 11pt;
        font-weight: normal;
        color: #8F8F8F;
        margin-top: 1rem;
      }

      p {
        font-size: 10pt;
        background-color: #F6F7F9;
        border-radius: 4px;
        padding: 10px 15px;
        margin: 10px 10px 0px 0px;
      }
    }
  }

  .footer {
    p {
      font-size: 10pt;
      color: gray;
      text-align: center;
      align-self: flex-end;
      margin-bottom: 10px;
      margin-top: 25px;
    }
  }
}