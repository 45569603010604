@media screen and (min-width: 992px) {
  div.MenuKey {
    width: 50%;
  }
}

.MenuKey {
  background-color: white;
  border-radius: 0 0 10px 10px;
  z-index: 2;
  display: flex;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  width: 100vw;

  -webkit-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.06);

  .color-keys {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    padding: 20px 20px;

    .key-col {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .eat, .modify, .cross, .unsuitable {
      display: flex;
      margin-right: 20px;
      gap: 0.3rem;

      img, p {
        margin-top: auto;
        margin-bottom: auto;
      }

      img {
        height: 25px;
        width: 25px;
        margin-right: -3px;

        // Offset shadow
        transform: translate(0px, 3px);
      }

      p {
        font-weight: bold;
        font-size: 0.7em;
        max-lines: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .eat p {
      color: #11A24E;
    }

    .modify p {
      color: #75AFEC;
    }

    .cross p {
      color: #F39200;
    }

    .unsuitable p {
      color: #F43D3D;
    }
  }

  .toggle {
    margin-right: 20px;
    margin-left: auto;
    margin-top: 20px;
    background-color: #F2F4F6;
    height: 15px;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    cursor: pointer;

    .inner {
      border-radius: 50%;
      height: 12px;
      width: 12px;
      border: solid 2px #F43D3D;
      margin-top: -1px;

      img {
        margin: -6px -4px;
        display: none;
      }

      &.active {
        // background-color: #F43D3D;

        img {
          display: block;
        }
      }
    }

    p {
      color: #484848;
      margin: -1.5px 10px;
      font-weight: bold;
      font-size: 8pt;

      .red {
        color: #F43D3D;
      }
    }
  }
}