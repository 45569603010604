.Option {
  margin-top: 20px;

  .icon {
    border: solid 0.5px #DCDFE1;
    border-radius: 6px;
    width: 95px;
    height: 80px;
    position: relative;

    img {
      width: 40px;
      height: 40px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      object-fit: contain;

      filter: sepia(100%) saturate(100%) brightness(30%) hue-rotate(180deg);
    }

    &.active {
      background-image: linear-gradient(#50B8F9, #04ADFF);

      img {
        filter: none;
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 10pt;
    text-align: center;
    margin-bottom: 5px;
    max-width: 90px;
  }
}